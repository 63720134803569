
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    /* margin: 0; */
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    color: #4a4a4a;
    background: #efefff;
    user-select: none;
    -webkit-user-drag: none;
}